import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { FormGroup, FormControl, ControlLabel, Panel, Col, Row } from 'react-bootstrap';
import SpinnerButton from '../components/SpinnerButton';
import DismissableAlert from '../components/DismissableAlert';
import './ResetPassword.css';
import config from '../config';

import logo from '../logo.png';
import axios from 'axios';
import qs from 'qs';

axios.defaults.withCredentials = true;

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        const parsed = qs.parse(this.props.location.search.substring(1));
        this.state = {
            isLoading: false,
            token: parsed.token,
            password: '',
            passwordverify: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.password.length > 0 &&
            this.state.passwordverify.length > 0 &&
            this.state.password === this.state.passwordverify
        );
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = (event) => {
        const { t } = this.props;
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/auth/reset`, {
                    password: this.state.password,
                    token: this.state.token
                })
                .then(function(response) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('ResetPassword.success')}:`,
                            message: `${t('ResetPassword.reset_message')}`,
                            style: 'success'
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('ResetPassword.error')}:`,
                            style: 'danger',
                            message: `${t('ResetPassword.unknown_error')}`
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('ResetPassword.error')}:`,
                    message: `${t('ResetPassword.unknown_error')}`,
                    style: 'danger'
                }
            });
        }
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t('ResetPassword.title')}`;

        return (
            <div className="ResetPassword">
                <Row>
                    <Col xs={10} sm={6} md={6} lg={4} xsOffset={1} smOffset={3} mdOffset={3} lgOffset={4}>
                        <Panel>
                            <Panel.Body>
                                <form onSubmit={this.handleSubmit}>
                                   
                                    <a href="https://mysign.uk.com">
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className="center-block img-responsive"
                                            style={{ width: 400, height: 123 }}
                                        />
                                    </a>

                                    <DismissableAlert
                                        title={this.state.error.title}
                                        message={this.state.error.message}
                                        hidden={this.state.error.hidden}
                                        style={this.state.error.style}
                                    />

                                    <FormGroup controlId="password" bsSize="sm">
                                        <ControlLabel>{t('ResetPassword.password')}</ControlLabel>
                                        <FormControl
                                            autoFocus
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            type="password"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="passwordverify" bsSize="sm">
                                        <ControlLabel>{t('ResetPassword.reenter_password')}</ControlLabel>
                                        <FormControl
                                            value={this.state.passwordverify}
                                            onChange={this.handleChange}
                                            type="password"
                                        />
                                    </FormGroup>
                                    <SpinnerButton
                                        block
                                        bsStyle="primary"
                                        bsSize="large"
                                        disabled={!this.validateForm()}
                                        type="submit"
                                        isLoading={this.state.isLoading}
                                        text={`${t('ResetPassword.reset_password')}`}
                                        loadingText={`${t('ResetPassword.resetting_password')}`}
                                    />
                                </form>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(ResetPassword);
