import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { FormGroup, FormControl, ControlLabel, Button, Panel, Col, Row } from 'react-bootstrap';
import SpinnerButton from '../components/SpinnerButton';
import DismissableAlert from '../components/DismissableAlert';
import './Register.css';
import config from '../config';

import logo from '../logo.png';
import axios from 'axios';
import qs from 'qs';

axios.defaults.withCredentials = true;

class Register extends Component {
    constructor(props) {
        super(props);

        const parsed = qs.parse(this.props.location.search.substring(1));
        this.state = {
            isLoading: false,
            invite: parsed.invite,
            registered: false,
            firstName: '',
            lastName: '',
            password: '',
            passwordverify: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.password.length > 0 &&
            this.state.passwordverify.length > 0 &&
            this.state.password === this.state.passwordverify
        );
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = (event) => {
        const { t } = this.props;
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/invites/${this.state.invite}/register`, {
                    password: this.state.password,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName
                })
                .then(function(response) {
                    self.setState({
                        registered: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('Register.success')}:`,
                            message: `${t('Register.created_message')}`,
                            style: 'success'
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('Register.error')}:`,
                            style: 'danger',
                            message: `${t('Register.unknown_error')}`
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('Register.error')}:`,
                    message: `${t('Register.unknown_error')}`,
                    style: 'danger'
                }
            });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="firstName" bsSize="sm">
                    <ControlLabel>{t('Register.first_name')}</ControlLabel>
                    <FormControl autoFocus value={this.state.firstName} onChange={this.handleChange} type="text" />
                </FormGroup>
                <FormGroup controlId="lastName" bsSize="sm">
                    <ControlLabel>{t('Register.last_name')}</ControlLabel>
                    <FormControl value={this.state.lastName} onChange={this.handleChange} type="text" />
                </FormGroup>
                <FormGroup controlId="password" bsSize="sm">
                    <ControlLabel>{t('Register.password')}</ControlLabel>
                    <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
                </FormGroup>
                <FormGroup controlId="passwordverify" bsSize="sm">
                    <ControlLabel>{t('Register.reenter_password')}</ControlLabel>
                    <FormControl value={this.state.passwordverify} onChange={this.handleChange} type="password" />
                </FormGroup>
                <SpinnerButton
                    block
                    bsStyle="primary"
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text={`${t('Register.register_account')}`}
                    loadingText={`${t('Register.registering_account')}`}
                />
            </form>
        );
    }

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t('Register.title')}`;

        let button;
        if (this.state.registered) {
            button = (
                <Button block bsStyle="primary" bsSize="large" href="/login" type="submit">
                    {t('Register.proceed_to_login')}
                </Button>
            );
        } else {
            button = null;
        }

        return (
            <div className="Register">
                <Row>
                    <Col xs={10} sm={6} md={6} lg={4} xsOffset={1} smOffset={3} mdOffset={3} lgOffset={4}>
                        <Panel>
                            <Panel.Body>

                                <a href="https://mysign.uk.com">
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className="center-block img-responsive"
                                        style={{ width: 400, height: 123 }}
                                    />
                                </a>

                                <DismissableAlert
                                    title={this.state.error.title}
                                    message={this.state.error.message}
                                    hidden={this.state.error.hidden}
                                    style={this.state.error.style}
                                />
                                {!this.state.registered ? this.renderForm() : null}
                                {button}
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(Register);
