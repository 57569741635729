import React, { Component, useRef, Fragment } from 'react';

import { translate } from 'react-i18next';
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Panel,
    Col,
    Row
} from 'react-bootstrap';
import SpinnerButton from '../components/SpinnerButton';
import DismissableAlert from '../components/DismissableAlert';
import './Login.css';
import config from '../config';
import { Link } from 'react-router-dom';

import logo from '../logo.png';
import cyberlogo from '../cyber.jpg';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

axios.defaults.withCredentials = true;

class Login extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            sentEmail: false,
            email: '',
            saved: false,
            captcha: '',
            token: '',
            secret: '',
            remember: false,
            password: '',
            twoFactorRequired: false,
            twoFactorSetupRequired: false,
            authToken: '',
            userUUID: '',
            qrCode: '',
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    componentDidMount() {}

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    onCaptchaChange = value => {
        this.setState({
            captcha: value
        });
    };

    sendEmailToken = () => {

        const { t } = this.props;

        axios
        .post(`${config.baseURL}/auth/two_factor_token`, {})
        .then((response) => {
            this.setState({ sentEmail: true,  error: {
                hidden: false,
                title: `${t('Login.two_fa_required')}:`,
                message: `${t('Login.provide_two_fa_code')}`,
                style: 'warning'
            } });
        })
        .catch((error) => {
            console.log(error);
        });
    }

    generateSecret() {
        var self = this;

        axios
            .post(`${config.baseURL}/auth/generateSecretLogin`, {})
            .then(function(response) {
                self.setState({ qrCode: response.data.qrCode, secret: response.data.secret });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    renderFormContent() {

        const { t } = this.props;

        const { twoFactorRequired, twoFactorSetupRequired, saved} = this.state;
        if (twoFactorRequired) {
            
            return (
                <Fragment>
                    <FormGroup controlId="authToken" bsSize="sm">
                        <ControlLabel>
                            {t('Login.auth_code')}
                        </ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.authToken}
                            onChange={this.handleChange}
                        />
                         <small>
                            {this.state.sentEmail ? (
                                <span>Email Sent! Please check your inbox.</span>
                            ) : (
                                <>
                                {t('Login.dont_have_device')}{' '}
                                <a href="#" onClick={this.sendEmailToken}>
                                    {t('Login.request_via_email')}
                                </a>
                                </>
                            )}
                        </small>
                    </FormGroup>
                    
                    <SpinnerButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={`${t('Login.login')}`}
                        loadingText={`${t('Login.logging_in')}`}
                    />
                </Fragment>
                
            );

        } else if (saved) {


            return (
                <Fragment>
            
                    <SpinnerButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={`${t('Login.re_authenticate')}`}
                        loadingText={`${t('Login.navigating')}`}
                    />
                </Fragment>
                
            );

        } else if (twoFactorSetupRequired) {
            return (
                <Fragment>
                    <FormGroup controlId="setupInstructions" bsSize="sm">
                        <ControlLabel>
                            {t('Login.Token')}
                        </ControlLabel>
                    </FormGroup>
                    <img
                        src={this.state.qrCode}
                        alt="logo"
                        className="center-block img-responsive"
                        style={{ width: 212, height: 212 }}
                    />
                    <FormGroup controlId="token" bsSize="sm">
                        <ControlLabel>
                            {t('Login.auth_code')}
                        </ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.token}
                            onChange={this.handleChange}
                        />
                        <small className="form-text text-muted">
                            {t('CreateGlobalPageForm.required')}
                        </small>
                    </FormGroup>
                    <SpinnerButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={`${t('Login.verify_secret')}`}
                        loadingText={`${t('Login.verifying_secret')}`}
                    />
                </Fragment>
            );
       
        } else {
            return (
                <Fragment>
                    <FormGroup controlId="email" bsSize="sm">
                        <ControlLabel>
                            {t('Login.email')}
                        </ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="sm">
                        <ControlLabel>
                            {t('Login.password')}
                        </ControlLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                        />
                        <small>
                            {t('Login.forgot_password')}{' '}
                            <Link to="/login/forgotten">
                                {t('Login.reset_here')}
                            </Link>
                        </small>
                    </FormGroup>
                    <FormGroup>
                        <ReCAPTCHA
                            sitekey={"6LcD22cqAAAAABTzBSLghlV_a07LIOBUMpYK0Oo6"}
                            onChange={this.onCaptchaChange}
                            ref={recaptchaRef}
                        />
                    </FormGroup>
                    <SpinnerButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={`${t('Login.login')}`}
                        loadingText={`${t('Login.logging_in')}`}
                    />
                </Fragment>
            );
        }
    }
    

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        var self = this;

        try {
            if (self.state.saved) {

                this.setState({
                    isLoading: false,
                    email: '',
                    captcha: '',
                    sentEmail: false,
                    token: '',
                    saved: false,
                    secret: '',
                    remember: false,
                    password: '',
                    twoFactorRequired: false,
                    twoFactorSetupRequired: false,
                    authToken: '',
                    userUUID: '',
                    qrCode: '',
                    error: {
                        hidden: true,
                        title: '',
                        message: '',
                        style: 'danger'
                    }
                });

            } else if (!self.state.twoFactorRequired && !self.state.twoFactorSetupRequired) {
            axios
                .post(`${config.baseURL}/auth/login`, {
                    email: this.state.email,
                    password: this.state.password,
                    remember: this.state.remember,
                    captcha: this.state.captcha
                })
                .then(function(response) {
                    const user = response.data.user;
                    user.isAuthenticated = true;

                    let roles = new Set();
                    for (var key in user.organisationRoles) {
                        const organisationRoles = user.organisationRoles[key];
                        for (const role of organisationRoles) {
                            roles.add(role);
                        }
                    }

                    if (roles.has("staff_engineer")) {
                        user.isEngineer = true;
                    } else {
                        user.isEngineer = false;
                    }


                    self.props.userHasAuthenticated(true, user);
                })
                .catch(function(error) {

                    if(recaptchaRef) {
                        // recaptchaRef.reset();
                        recaptchaRef.current.reset();
                    }

                    console.log(`Error: ${JSON.stringify(error)}`);
                    if (error && error.response && error.response.data && error.response.data.message == 'ReCAPTCHA error') {

                        self.setState({
                            isLoading: false,
                            error: {
                                hidden: false,
                                title: `${t('Login.error')}:`,
                                message: `${t('Login.incorrect_captcha')}`,
                                style: 'danger'
                            }
                        });
                    } else if (error && error.response && error.response.data && error.response.data.twoFactorRequired) {
                        console.log(`User UUID needs 2FA: ${error.response.data.userUUID}`);
                        self.setState({
                            isLoading: false,
                            twoFactorRequired: true,
                            userUUID: error.response.data.userUUID,
                            error: {
                                hidden: false,
                                title: `${t('Login.two_fa_required')}:`,
                                message: `${t('Login.provide_two_fa_code')}`,
                                style: 'warning'
                            }
                        });
                    } else if (error && error.response && error.response.data && error.response.data.awaiting2FASetup) {

                        console.log(`User UUID needs 2FA setup: ${error.response.data.userUUID}`);
                        self.generateSecret();
                        self.setState({
                            isLoading: false,
                            twoFactorSetupRequired: true,
                            userUUID: error.response.data.userUUID,
                            error: {
                                hidden: false,
                                title: `${t('Login.two_fa_required')}:`,
                                message: `${t('Login.setup_two_fa')}`,
                                style: 'warning'
                            }
                        });
                    } else {
                        self.setState({
                            isLoading: false,
                            error: {
                                hidden: false,
                                title: `${t('Login.error')}:`,
                                message: `${t('Login.incorrect_credentials')}`,
                                style: 'danger'
                            }
                        });
                    }
                    
                });

            } else if (self.state.twoFactorSetupRequired) {

            axios
                .post(`${config.baseURL}/auth/verifySecretSetupLogin`, {
                    token: self.state.token            
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('Login.success')}:`,
                            style: 'success',
                            message: `${t(
                                'EditUserTwoFactorAuthenticationForm.saved_message'
                            )}`
                        }
                    });

                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('Login.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });

            } else {
                // 2FA Verification
                axios.post(`${config.baseURL}/auth/verify`, {
                    userUUID: this.state.userUUID,
                    authToken: this.state.authToken,
                })
                .then(function(response) {

                    const user = response.data.user;
                    user.isAuthenticated = true;

                    let roles = new Set();
                    for (var key in user.organisationRoles) {
                        const organisationRoles = user.organisationRoles[key];
                        for (const role of organisationRoles) {
                            roles.add(role);
                        }
                    }

                    if (roles.has("staff_engineer")) {
                        user.isEngineer = true;
                    } else {
                        user.isEngineer = false;
                    }


                    self.props.userHasAuthenticated(true, user);
                })
                .catch(function(error) {

                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('Login.error')}:`,
                            message: `${t('Login.incorrect_token')}`,
                            style: 'danger'
                        }
                    });
                    
                });
            }
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('Login.error')}:`,
                    message: `${t('Login.unknown_error')}:`,
                    style: 'danger'
                }
            });
        }
    };

    render() {
        const { t } = this.props;

        document.title = `MySign Digital Screen Solutions | ${t(
            'Login.title'
        )}`;

        return (
            <div className="Login">
                <Row>
                    <Col
                        xs={10}
                        sm={6}
                        md={6}
                        lg={4}
                        xsOffset={1}
                        smOffset={3}
                        mdOffset={3}
                        lgOffset={4}
                    >
                        <Panel>
                            <Panel.Body>
                            
                                <form onSubmit={this.handleSubmit}>
                                <a href="https://mysign.uk.com">
                                <img
                                    src={logo}
                                    alt="logo"
                                    className="center-block img-responsive"
                                    style={{ width: 400, height: 123 }}
                                />
                                </a>

                                    <DismissableAlert
                                        title={this.state.error.title}
                                        message={this.state.error.message}
                                        hidden={this.state.error.hidden}
                                        style={this.state.error.style}
                                    />

                                    {this.renderFormContent()}

                                </form>
                            </Panel.Body>
                        </Panel>
                        <small style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Link to="/terms" style={{ marginRight: '0.5rem' }} >
                                {t('Login.terms')}
                            </Link>
                            {' | '}
                            <Link to="/privacy" style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }} >
                                {t('Login.privacy')}
                            </Link>
                            {' | '}
                            <a href="https://mysign.uk.com" style={{ marginLeft: '0.5rem' }} >
                                MySign Home Page
                            </a>
                        </small>
                        <img
                                    src={cyberlogo}
                                    alt="logo"
                                    className="center-block img-responsive"
                                    style={{ width: 400, height: 100 }}
                                />

                    </Col>
                </Row>
            </div>
        );
    }
}

export default translate('translations')(Login);
